// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';
require('prismjs/themes/prism.css');
require('katex/dist/katex.min.css');

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript(
      'https://ajax.googleapis.com/ajax/libs/jquery/1.5.2/jquery.min.js'
    );
    addScript('https://s3.amazonaws.com/phplist/phplist-subscribe-0.2.min.js');
  };
};
